import React, { useState } from "react";
import { Grid, Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { menuOptions } from "../../../../text/menuOptions";
import "./styles.css";

export default function TextMenu() {
  const navigate = useNavigate();
  const [clickedLink, setClickedLink] = useState("Start");

  const handleClick = (option) => {
    switch (option) {
      case "Våra verktyg":
        navigate("/vara-verktyg");
        setClickedLink("Våra verktyg")
        break;
      case "Hur det funkar":
        navigate("/hur-det-funkar");
        setClickedLink("Hur det funkar")
        break;
      case "Hyresvillkor":
        navigate("/hyresvillkor");
        setClickedLink("Hyresvillkor")
        break;
      case "Lär känna oss":
        navigate("/om-oss");
        setClickedLink("Lär känna oss")
        break;
      case "Kontakt":
        navigate("/kontakt");
        setClickedLink("Kontakt")
        break;
      default:
        navigate("/");
        setClickedLink("Start")
    }
  };

  return (
    <Grid item lg={6} xl={5}>
      <Grid container justifyContent="space-between">
        {menuOptions.map((option) => (
          <Box key={option}>
            <Typography
              variant="body1"
              color={option === clickedLink ? "secondary" : "primary"} 
              onClick={() => handleClick(option)}
              className="menuLink"
            >
              {option}
            </Typography>
          </Box>
        ))}
      </Grid>
    </Grid>
  );
}
