export const howItWorksText = [
  {
    id: "1",
    title: "Hitta ditt verktyg...",
    text: "via vår hemsida. Hittar du inte det du söker eller är osäker på vilket verktyg du behöver, kontakta oss via telefon eller mail.",
  },
  {
    id: "2",
    title: "Hyr verktyget...",
    text: "genom att ringa, maila eller besöka vår fysiska butik.",
  },
  {
    id: "3",
    title: "Hämta eller få...",
    text: "verktyget utkört tidigast morgonen därpå.",
  },
  {
    id: "4",
    title: "Lämna tillbaka...",
    text: "i butik eller få verktyget hämtat på överenskommen morgon.",
  },
];
