import { StyledPage } from "./styles";

const Page = ({ children }) => {
  return (
    <StyledPage container>
      {children}
    </StyledPage>
  );
};

export default Page;
