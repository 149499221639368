import { createTheme } from "@mui/material";

const defaultTheme = createTheme();
export const theme = createTheme({
  palette: {
    primary: {
      main: "#FFFFFF",
    },
    secondary: {
      main: "#FF7A00",
    },
  },
  typography: {
    fontFamily: "'Questrial', sans-serif",
    h1: {
      [defaultTheme.breakpoints.down("lg")]: {
        fontSize: "4rem",
      },
      [defaultTheme.breakpoints.down("sm")]: {
        fontSize: "2.4rem",
      },
    },
    h2: {
      [defaultTheme.breakpoints.down("md")]: {
        fontSize: "2.5rem",
        marginLeft: '3rem',
      },
    },
    h3: {
      [defaultTheme.breakpoints.down("sm")]: {
        fontSize: "2.5rem",
      },
    },
    h4: {
      [defaultTheme.breakpoints.down("sm")]: {
        fontSize: "1.5rem",
      },
    },
    body1: { fontSize: "20px" },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
  },
});
