import React from "react";
import { Grid } from "@mui/material";
import MenuLink from "./components/MenuLink";
import MenuContainer from "./components/MenuContainer";
import LinksContainer from "./components/LinksContainer";
import { useAppContext } from "../../context";
import { menuOptions } from "../../text/menuOptions";
import CloseMenu from "./components/CloseMenu";

export default function Menu() {
  const { showMenu } = useAppContext();
  if(showMenu === undefined) return null;
  
  return (
    <MenuContainer>
      <CloseMenu />
      <Grid container justifyContent="center" marginTop="5rem">
        <LinksContainer>
          {menuOptions.map((option) => (
            <MenuLink option={option} key={option} />
          ))}
        </LinksContainer>
      </Grid>
    </MenuContainer>
  );
}
