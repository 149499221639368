import { ThemeProvider } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import Layout from "./components";
import AppContextProvider from "./context";
import { theme } from "./theme";

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <AppContextProvider>
          <Layout />
        </AppContextProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
