import { StyledPageDevider } from "./styles";
import { Grid } from "@mui/material";

export const PageDevider = () => {
  
  return (
    <Grid container>
        <Grid item xs={12}>
          <StyledPageDevider />
        </Grid>
      </Grid>
  );
};

export default PageDevider;
