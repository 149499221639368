import { StyledFrame, StyledMaxWidth } from "./styles";
import { Grid } from "@mui/material";

const Frame = ({ children }) => {
  return (
    <StyledFrame container>
      <StyledMaxWidth container>
        <Grid item xs={12}>
          {children}
        </Grid>
      </StyledMaxWidth>
    </StyledFrame>
  );
};

export default Frame;
