import { Grid, Box } from "@mui/material";
import React from "react";
import "./styles.css";
import logo from "../../images/logo.png";

import { useAppContext } from "../../context";
import HamburgerMenu from "./components/HamburgerMenu";
import TextMenu from "./components/TextMenu";

export default function Header() {
  const { screenSize } = useAppContext();
  
  const getMenuOptions = () => {
    if (screenSize < "1200") return <HamburgerMenu />
    return <TextMenu />
  };
  return (
    <Grid container className="headerRoot">
      <Grid item xs={11} lg={10} style={{ maxWidth: "90rem", margin: "0 auto" }}>
        <Grid container className="headerContainer">
          <Grid item xs={5} lg={2}>
            <Box className="headerLogo">
              <img src={logo} alt="Logo" style={{ height: "100%" }} />
            </Box>
          </Grid>
          {getMenuOptions()}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box className="headerLine" />
        <Box className="headerBottom" />
      </Grid>
    </Grid>
  );
}
