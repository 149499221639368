import React from "react";
import { Typography, Grid } from "@mui/material";
import Page from "../../StyledComponents/Page";
import PageHeader from "../../StyledComponents/PageHeader";
import PageBody from "../../StyledComponents/PageBody";
import Frame from "../../StyledComponents/Frame";
import ToolCard from "./ToolCard";

export default function OurTools() {
  return (
    <Page>
      <PageHeader>
        <Typography variant="h3" color="primary">
          Våra verktyg
        </Typography>
        <Typography variant="body1" color="primary">
          Välj kategori
        </Typography>
      </PageHeader>
      <PageBody>
        <Frame>
          <Grid container spacing={3} justifyContent="center">
            <ToolCard />
            <ToolCard />
            <ToolCard />
            <ToolCard />
          </Grid>
        </Frame>
      </PageBody>
    </Page>
  );
}
