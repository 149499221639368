import React from "react";
import Header from "./Header";
import Menu from "./Menu";
import Pages from "./pages";

export default function Layout() {
  return (
    <>
      <Header />
      <Menu />
      <Pages />
    </>
  );
}
