import React from "react";
import { Grid, Typography } from "@mui/material";
import "./styles.css";

export default function Phone() {
  return (
    <Grid item textAlign="center" className="phoneContainer">
      <Grid item className="phoneTitle">
        <Typography variant="h4" color="primary" gutterBottom>
          Telefon
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" color="primary">
          <a className="phoneLink" href="tel:0737201217">
            0737-201217
          </a>
        </Typography>
      </Grid>
    </Grid>
  );
}
