import React from "react";
import Page from "../../StyledComponents/Page";
import PageHeader from "../../StyledComponents/PageHeader";
import PageBody from "../../StyledComponents/PageBody";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import "./styles.css";
import { conditionsText } from "../../../text/conditionsText";

export default function Conditions() {
  return (
    <Page>
      <PageHeader>
        <Typography variant="h3" color="primary">
          Allmänna hyresvillkor
        </Typography>
      </PageHeader>
      <PageBody>
        <Grid container rowSpacing={2} className="faqAccordion">
          {conditionsText.map((condition) => (
            <Grid item xs={12} key={condition.id}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>} >
                  <Typography variant="h5" margin=".3rem 0">{condition.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container rowSpacing={3} marginBottom="1rem">
                    {condition.text.map((text) => (
                      <Grid item key={text}>
                        <Typography variant="body1">{text}</Typography>
                      </Grid>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          ))}
        </Grid>
      </PageBody>
    </Page>
  );
}
