import { Box } from "@mui/material";
import { styled } from "@mui/system";

export const StyledPageDevider = styled(Box, {
  name: "StyledPageDevider",
  slot: "PageDevider",
})`
  margin-top: 7rem;
  width: 100%;
  height: 0.5rem;
  background: linear-gradient(rgba(77, 69, 63, 0) 0%, #443e39 100%);
  box-shadow: inset 0px 2px 4px #1d1a17;
`;
