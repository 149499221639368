import { Grid } from "@mui/material";
import { styled } from "@mui/system";

export const StyledPage = styled(Grid, { name: "StyledPage", slot: "Page" })`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: #37302a;
  padding-bottom: 5rem;
`;
