import React from "react";
import { Grid, Typography } from "@mui/material";
import PrimaryButton from "../../../StyledComponents/PrimaryButton";
import "./styles.css";

export default function Address() {
  const mapUrl = "https://www.google.se/maps/place/Natev%C3%A4gen+4,+445+57+Surte/@57.8209356,12.0129677,17z/data=!3m1!4b1!4m5!3m4!1s0x46455f945fd878f3:0xc52dbc1587363dd9!8m2!3d57.8209328!4d12.0151564?hl=sv"
  return (
    <Grid textAlign="center" className="adressContainer">
      <Grid item className="addressTitle">
        <Typography variant="h4" color="primary" gutterBottom>
          Adress
        </Typography>
      </Grid>
      <Grid itemScope>
        <Typography variant="body1" color="primary">
          Natevägen 4
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" color="primary">
          445 57 Surte
        </Typography>
      </Grid>
      <PrimaryButton text='Se karta' link={mapUrl} />
    </Grid>
  );
}
