import { Grid } from "@mui/material";
import { styled } from "@mui/system";

export const StyledCloseMenu = styled(Grid, {
  name: "StyledCloseMenu",
  slot: "CloseMenu",
})`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4.5rem;
`;