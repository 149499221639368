import { Box } from "@mui/material";
import { styled, keyframes } from "@mui/system";

const toggleMenu = (props) => keyframes`
    0% {top: ${props.showMenu ? "-100vh" : "0"} };
    100% {top: ${props.showMenu ? "0" : "-100vh"}};
`;

export const StyledMenuContainer = styled(Box, {
  name: "StyledMenu",
  slot: "Menu",
})`
  position: fixed;
  z-index: 50;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #ff7a00;
  animation: ${(props) => toggleMenu(props)} 1s ease forwards;
`;
