import React from "react";
import { Grid, Typography } from "@mui/material";
import "./styles.css";
import logo from "../../../../images/logo.png";

export default function ToolCard() {
  return (
    <Grid item xs={11} sm={5} lg={3}>
      <Grid item className="toolFrame">
        <Grid item xs={12} className="toolImageContainer">
          <img src={logo} alt="Logo" className="toolImage" />
        </Grid>
        <Grid item xs={12} className="toolTextContainer">
          <Typography variant="h5" color="primary">Kategori</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
