import { styled } from "@mui/system";
import { Grid } from "@mui/material";

export const StyledLink = styled(Grid, {
  name: "StyledLink",
  slot: "Link",
})`
display: flex;
justify-content: center;
  cursor: pointer;
  width: 100%;
  margin: 1.2rem 0;
`;

export const ActiveLink = styled(Grid, {
  name: "StyledActiveLink",
  slot: "ActiveLink",
})`
  padding: 1rem 0 1rem 1rem;
  background-color: #F87803;
  box-shadow: inset 0px 2px 8px 1px #D56600;
  border-radius: 3px;
`;
