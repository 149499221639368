import React from "react";
import { Grid } from "@mui/material";
import MenuButton from "../../../StyledComponents/MenuButton";

export default function HamburgerMenu() {
  return (
    <Grid item xs={5} style={{ display: 'flex', justifyContent: "flex-end" }}>
      <MenuButton />
    </Grid>
  );
}
