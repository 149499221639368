import React from "react";
import { Typography, Grid } from "@mui/material";
import Page from "../../StyledComponents/Page";
import { howItWorksText } from "../../../text/howItWorksText";
import StepBlock from "./StepBlock";
import PageHeader from "../../StyledComponents/PageHeader";
import PageBody from "../../StyledComponents/PageBody";
// import Faq from "./Faq";
// import PageDevider from "../../StyledComponents/PageDevider";

export default function HowItWorks() {
  return (
    <Page>
      <PageHeader>
        <Typography variant="h3" color="primary">
          Hur det funkar
        </Typography>
        <Typography variant="body1" color="primary">
          Hyr dina verktyg med fyra enkla steg
        </Typography>
      </PageHeader>
      <PageBody>
        <Grid container spacing={3}>
          {howItWorksText.map((textBlock) => (
            <StepBlock {...textBlock} key={textBlock.id} />
          ))}
        </Grid>
      </PageBody>
      {/* <PageDevider />
      <PageBody>
        <Faq />
      </PageBody> */}
    </Page>
  );
}
