import { Button } from "@mui/material";
import { styled } from "@mui/system";

export const StyledButton = styled(Button, { name: "StyledButton", slot: "Button" })`
  color: white;
  background-color: ${(props) => (props.disabled ? "#B0B0B0" : "#ff7a00")};
  padding: 0.5rem 3rem;
  font-size: 1.2rem;
  font-family: 'Questrial', sans-serif;
  transition: 0.2s ease-out;
  margin: 3rem;
  :hover {
      padding: 0.5rem 3.5rem;
      background-color: #ff7a00;
  }
`;