import React, { useState } from "react";
import { Typography, Grid } from "@mui/material";
import { StyledLink, ActiveLink } from "./styles";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../../../context";

export default function MenuLink({ option }) {
  const { setShowMenu } = useAppContext();
  const [clickedLink, setClickedLink] = useState();
  const activePage = window.location.pathname;
  const navigate = useNavigate();
  console.log(activePage);

  const handleClick = (option) => {
    switch (option) {
      case "Våra verktyg":
        navigate("/vara-verktyg");
        setClickedLink("/vara-verktyg");
        setShowMenu(false);
        break;
      case "Hur det funkar":
        navigate("/hur-det-funkar");
        setClickedLink("/hur-det-funkar");
        setShowMenu(false);
        break;
      case "Hyresvillkor":
        navigate("/hyresvillkor");
        setClickedLink("/hyresvillkor");
        setShowMenu(false);
        break;
      case "Lär känna oss":
        navigate("/om-oss");
        setClickedLink("/om-oss");
        setShowMenu(false);
        break;
      case "Kontakt":
        navigate("/kontakt");
        setClickedLink("/kontakt");
        setShowMenu(false);
        break;
      default:
        navigate("/");
        setClickedLink("/");
        setShowMenu(false);
    }
  };

  return (
    <StyledLink container onClick={() => handleClick(option)}>
      {clickedLink === activePage ? (
        <ActiveLink item xs={11} md={12}>
          <Typography variant="h2" color="primary">
            {option}
          </Typography>
        </ActiveLink>
      ) : (
        <Grid item xs={11} md={12}>
          <Typography variant="h2" color="primary" paddingLeft="1rem">
            {option}
          </Typography>
        </Grid>
      )}
    </StyledLink>
  );
}
