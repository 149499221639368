import { Grid, Typography } from "@mui/material";
import React from "react";
import Page from "../../StyledComponents/Page";
import PrimaryButton from "../../StyledComponents/PrimaryButton";

export default function MailConfirmation() {
  return (
    <Page>
      <Grid
        container
        flexDirection="column"
        alignItems="center"
        marginTop="10rem"
      >
        <Grid item>
          <Typography variant="h3" color="primary">
            Tack för ditt mail!
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1" color="primary">
            Vi har mottagit ditt mail och hör av oss inom kort
          </Typography>
        </Grid>
        <Grid item>
          <PrimaryButton text="Okej" route="/kontakt"/>
        </Grid>
      </Grid>
    </Page>
  );
}
