import { Grid } from "@mui/material";
import { styled } from "@mui/system";

export const StyledFrame = styled(Grid, { name: "StyledFrame", slot: "Frame" })`
  display: flex;
  justify-content: center;
  background-color: #37302a;
  box-shadow: inset 0px 1px 8px 1px rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  padding: 2rem 0.5rem;
  max-width: 80rem;
  margin: 0 auto;
`;

export const StyledMaxWidth = styled(Grid, {
  name: "StyledMaxWidth",
  slot: "MaxWidth",
})`
  max-width: 60rem;
`;
