import styled from "@emotion/styled";
import { Grid } from "@mui/material";

export const StyledLinksContainer = styled(Grid, {
    name: "StyledLinksContainer",
    slot: "MenuLinks",
  })`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 30rem;
  `;