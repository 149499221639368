import React from "react";
import { Grid, Typography } from "@mui/material";
import Page from "../../StyledComponents/Page";
import PageHeader from "../../StyledComponents/PageHeader";
import PageBody from "../../StyledComponents/PageBody";
import Frame from "../../StyledComponents/Frame";

export default function About() {
  return (
    <Page>
      <PageHeader>
        <Typography variant="h3" color="primary">
          Lär känna oss
        </Typography>
      </PageHeader>
      <PageBody>
        <Frame>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={11}>
              <Typography variant="body1" color="primary" textAlign="center">
                Hos oss har du möjlighet att hyra byggmaskiner, stora som små.
                Vår nybyggda hyrdepå är belägen i Surte, bara 15 minuter ifrån
                Göteborgs innerstad. Vårt utbud består av välkända märken inom
                branschen som vi vet håller hög standard. Utöver vårt stora
                utbud av maskiner erbjuder vi även utkörning till önskad adress.
              </Typography>
            </Grid>
            <Grid item xs={11} marginBottom={6}>
              <Typography variant="body1" color="primary" textAlign="center">
                Kom in på en kaffe och berätta om dina projekt, vi hjälper dig
                att hitta det du behöver.
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Typography variant="h5" color="secondary">
                Vi är mer än bara maskiner
              </Typography>
              <Typography variant="body1" color="primary" marginBottom={4}>
                Vi har lång erfarenhet av byggbranschen och vet därför att rätt
                maskin/verktyg kan vara avgörande för ett lyckat projekt. Om du
                är osäker på vilket verktyg du söker, eller hur du ska gå
                tillväga, hjälper vi dig gärna! Vi erbjuder full service genom
                hela ditt projekt, från början till slut.
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Typography variant="h5" color="secondary">
                Våra ambitioner
              </Typography>
              <Typography variant="body1" color="primary">
                Vi på hyrdepån vill att fler ska ha tillgång till
                proffsutrustning till ett rimligt pris. Vår ambition är att
                fungera som ditt garage där du kan hitta utrustningen du söker.
                När projektet väl är klart tar utrustningen dessutom ingen plats
                då de återlämnas till oss och kan användas av nästa person. Vi
                anser att uthyrning av verktyg är ett steg i rätt riktning mot
                ett mer hållbart samhälle då verktygen används istället för att
                bli liggandes. För oss är hållbar utveckling viktigt. Därför
                letar vi ständigt efter utrustning som drivs av alternativ till
                fossila bränslen, men som samtidigt håller hög kvalitet.
              </Typography>
            </Grid>
          </Grid>
        </Frame>
      </PageBody>
    </Page>
  );
}
