import { useAppContext } from "../../../context";
import { Line, StyledMenuButton } from "./styles";

export const MenuButton = () => {
  const { setShowMenu, showMenu } = useAppContext();

  return (
    <StyledMenuButton
      showMenu={showMenu}
      onClick={() => setShowMenu(!showMenu)}
    >
      <Line showMenu first />
      <Line showMenu second />
      <Line showMenu third />
    </StyledMenuButton>
  );
};

export default MenuButton;
