import { StyledPageBody } from "./styles";
import { Grid } from "@mui/material";

const PageBody = ({ children }) => {
  return (
    <StyledPageBody container justifyContent="center">
      <Grid item xs={11} xl={8}>
        {children}
      </Grid>
    </StyledPageBody>
  );
};

export default PageBody;