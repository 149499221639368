import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import "./styles.css";

export default function StepBlock({ id, title, text }) {
  return (
    <Grid item xs={12}>
      <Grid container className="stepFrame">
        <Grid item xs={12} sm={1} md={1}>
          <Box className="stepNumber">
            <Typography variant="h4" color="primary">
              {id}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={9} md={10}>
          <Grid item >
            <Typography variant="h4" color="primary">
              {title}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" color="primary">
              {text}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
