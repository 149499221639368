import { StyledMenuContainer } from "./styles";
import { useAppContext } from "../../../../context";

const MenuContainer = ({ children }) => {
  const { showMenu } = useAppContext();
  return (
    <StyledMenuContainer showMenu={showMenu}>{children}</StyledMenuContainer>
  );
};

export default MenuContainer;
