import React from "react";
import { Typography, Grid } from "@mui/material";
import Page from "../../StyledComponents/Page";
import PageHeader from "../../StyledComponents/PageHeader";
import PageBody from "../../StyledComponents/PageBody";
import Address from "./Address";
import Phone from "./Phone";
import PageDevider from "../../StyledComponents/PageDevider";
import Mail from "./Mail";

export default function Contact() {
  return (
    <Page>
      <PageHeader>
        <Typography variant="h3" color="primary">
          Kontakt
        </Typography>
      </PageHeader>
      <PageBody>
        <Grid container rowSpacing={2} justifyContent="space-evenly" style={{maxWidth: '60rem', margin: '0 auto'}}>
          <Grid item xs={12} md={5}>
            <Address />
          </Grid>
          <Grid item xs={12} md={5}>
            <Phone />
          </Grid>
        </Grid>
      </PageBody>
      <PageDevider />
      <Mail />
    </Page>
  );
}
