import { StyledCloseMenu } from "./styles";
import { Grid } from "@mui/material";
import { useAppContext } from "../../../../context";
import CloseIcon from "@mui/icons-material/Close";

const CloseMenu = () => {
  const { setShowMenu, showMenu } = useAppContext();
  return (
    <StyledCloseMenu container>
      <Grid item xs={11} style={{ maxWidth: "90rem", margin: "0 auto" }}>
        <Grid container justifyContent="flex-end">
          <CloseIcon
            color="primary"
            style={{ fontSize: 50, cursor: 'pointer' }}
            onClick={() => setShowMenu(!showMenu)}
          />
        </Grid>
      </Grid>
    </StyledCloseMenu>
  );
};

export default CloseMenu;
