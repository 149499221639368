import React, { useState } from "react";
import { Typography, Grid, Box, TextField } from "@mui/material";
import PageHeader from "../../../StyledComponents/PageHeader";
import PageBody from "../../../StyledComponents/PageBody";
import PrimaryButton from "../../../StyledComponents/PrimaryButton";

export default function Mail() {
  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const validMail = name && message && (phone || mail.includes("@"));

  const inputStyle = {
    "& .MuiTextField-root": {
      mb: 3,
      width: "100%",
      backgroundColor: "white",
      borderRadius: "3px",
    },
  };

  return (
    <>
      <PageHeader>
        <Typography variant="h4" color="primary">
          Maila oss
        </Typography>
        <Typography variant="body1" color="primary">
          Fyll i mail eller telefon för att få svar
        </Typography>
      </PageHeader>
      <PageBody>
        <Box
          component="form"
          action="https://formsubmit.co/513209bd47dd7d8d137b77de56ddba75"
          method="POST"
          autoComplete="off"
          noValidate
          sx={inputStyle}
          marginBottom="5rem"
        >
          <Grid
            container
            justifyContent="center"
            style={{ maxWidth: "60rem", margin: "0 auto" }}
          >
            <Grid item xs={12} md={7}>
              <Typography color="primary" gutterBottom>
                Till
              </Typography>
              <TextField id="to" value="info@goteborgshyrdepa.se" disabled />
            </Grid>
            <Grid item xs={12} md={7}>
              <Typography color="primary" gutterBottom>
                Namn
              </Typography>
              <TextField
                id="name"
                required
                name="Namn"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <Typography color="primary" gutterBottom>
                Mail
              </Typography>
              <TextField
                id="mail"
                name="Mail"
                value={mail}
                onChange={(e) => setMail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <Typography color="primary" gutterBottom>
                Telefon
              </Typography>
              <TextField
                id="phone"
                name="Telefon"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <Typography color="primary" gutterBottom>
                Meddelande
              </Typography>
              <TextField
                id="message"
                multiline
                required
                rows={4}
                name="Meddelande"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </Grid>
            <input type="hidden" name="_captcha" value="false" />
            <input type="hidden" name="_template" value="box" />
            <input
              type="hidden"
              name="_subject"
              value={`${"Hemsida: " + name}`}
            />
            <input
              type="hidden"
              name="_next"
              value="https://goteborgshyrdepa.se/mail-bekraftelse"
            ></input>
            <Grid item xs={12} md={7} textAlign="center">
              {validMail ? (
                <PrimaryButton text="Skicka" sendMail />
              ) : (
                <PrimaryButton text="Skicka" disabled />
              )}
            </Grid>
          </Grid>
        </Box>
      </PageBody>
    </>
  );
}
