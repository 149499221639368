import { Box } from "@mui/material";
import { styled } from "@mui/system";

export const StyledMenuButton = styled(Box, {
  name: "StyledMenuButton",
  slot: "MenuButton",
})`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 2rem;
  cursor: pointer;
  :hover {
    & div {
      background-color: ${(props) => (props.showMenu ? 'white' : '#ff7a00')};
    }
  }
`;

export const Line = styled(Box)`
  background-color: white;
  height: 0.1rem;
  width: 3rem;
  transition: 0.2s linear;
`;
