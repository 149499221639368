import { StyledPageHeader } from "./styles";

const PageHeader = ({ children }) => {
  return (
    <StyledPageHeader item textAlign="center">
      {children}
    </StyledPageHeader>
  );
};

export default PageHeader;