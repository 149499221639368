import { Grid, Typography } from "@mui/material";
import React from "react";
import "./styles.css";
// import PrimaryButton from "../../StyledComponents/PrimaryButton";

export default function Start() {
  return (
    <Grid container className="startRoot">
      <Grid item>
        <Grid item xs={12} textAlign="center">
          <Typography variant="h4" color="primary" gutterBottom>
            Hyr dina verktyg hos
          </Typography>
          <Typography variant="h1" color="primary">
            Göteborgs Hyrdepå
          </Typography>
        </Grid>
        <Grid item xs={12} className="buttonContainer">
          {/* <PrimaryButton text={"Se utbud"} /> */}
        </Grid>
      </Grid>
    </Grid>
  );
}
