import { StyledButton } from "./styles";
import { useNavigate } from "react-router-dom";

const PrimaryButton = ({ text, link, sendMail, route, disabled }) => {
  const navigate = useNavigate();
  if (link) {
    return (
      <StyledButton href={link} target="_blank">
        {text}
      </StyledButton>
    );
  }
  if (sendMail) {
    return <StyledButton type="submit">{text}</StyledButton>;
  }
  if (disabled) {
    return <StyledButton disabled>{text}</StyledButton>;
  }
  return <StyledButton onClick={() => navigate(route)}>{text}</StyledButton>;
};

export default PrimaryButton;
