import React, { createContext, useContext, useState } from "react";

const appContext = createContext();
export const useAppContext = () => useContext(appContext);

const AppContextProvider = ({ children }) => {
  const [showMenu, setShowMenu] = useState();
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  window.addEventListener("resize", function () {
    setScreenSize(window.innerWidth);
  });

  return (
    <appContext.Provider value={{ showMenu, setShowMenu, screenSize }}>
      {children}
    </appContext.Provider>
  );
};

export default AppContextProvider;
