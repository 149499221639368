export const conditionsText = [
  {
    id: "1",
    title: "§1 Tillämplighet",
    text: [
      "Nedanstående villkor skall gälla om inte annat skriftligen avtalats mellan Uthyraren och hyrestagaren. Mottagandet av Hyresmateriel innebär accept av dessa Allmänna hyresvillkor.",
    ],
  },
  {
    id: "2",
    title: "§2 Leverans/återlämnande",
    text: [
      "Hyresmateriel levereras till Uthyrarens uthyrningsdepå och avhämtas där av hyrestagaren. Uthyraren kan efter överenskommelse med hyrestagaren anordna transport till hyrestagaren, varvid hyrestagaren står för transportkostnaden samt risken för Hyresmaterielen under transporten.",
      "Hyresmaterielen ska återlämnas vid Uthyrarens uthyrningsdepå. Återlämnande skall ske under Uthyrarens öppettider. Hyresmaterielen anses som återlämnad då tidssatt retursedel utfärdats av Uthyraren. Om Uthyraren efter överenskommelse har åtagit sig att anordna transport för återlämnande av Hyresmaterielen, ska transportkostnaden samt risken för Hyresmaterielen bäras av hyrestagaren under transporten.",
    ],
  },
  {
    id: "3",
    title: "§3 Hyrestid",
    text: [
      "A) Uthyrning för bestämd tid",
      "Materielen skall återlämnas inom avtalad tid. Önskar hyrestagaren förlänga hyrestiden skall nytt hyresavtal upprättas före returdatum. Hyrestagare som brukar Hyresmaterielen för längre tid än avtalad hyrestid är skyldig att betala hyra enligt § 4 för varje påbörjad hyresdag. Därutöver är hyrestagaren skyldig att ersätta Uthyraren för sådan direkt och indirekt skada som uppstår till följd av att Hyresmaterielen inte återlämnats inom avtalad tid.",
      "B) Uthyrning för obestämd tid (löpande hyresdebitering)",
      "Hyrestiden räknas från och med den dag då Hyresmaterielen levereras eller hålls tillgänglig för avhämtning till och med den dag då Hyresmaterielen återlämnas. I hyresavtalet skall anges att hyrestiden gäller tillsvidare, och att avtalet gäller till och med den dag materialen återlämnas.",
    ],
  },
  {
    id: "4",
    title: "§4 Hyresberäkning",
    text: [
      "Hyra debiteras enligt vid varje tidpunkt gällande prislista. Angiven dagshyra utgör hyrespris per maskin och dag vid enskiftsdrift. För del av dag utgår hyra som för hel dag. Vid utökad användning (flerskift) sker uppräkning som är proportionell mot angiven dagshyra. Alla veckodagar, även lördag, söndag och övriga helgdagar, debiteras till överenskomna hyresvillkor om inte annat skriftligen överenskommits. Grundhyra debiteras vid varje hyrestillfälle. För utrustning som enligt arbetsmiljölagen skall saneras, debiteras särskild saneringskostnad.",
    ],
  },
  {
    id: "5",
    title: "§5 Äganderätt till Hyresmateriel",
    text: [
      "Hyresmaterielen utgör under hela hyresperioden Uthyrarens egendom och ingenting i detta avtal ska innebära eller tolkas som att hyrestagaren har förvärvat eller kommer att förvärva någon äganderätt till egendom, eller annan rätt att belasta egendomen. Hyrestagare som i strid mot detta avtal förfogar över Hyresmaterielen kan komma att ådra sig ett skadeståndsrättsligt ansvar gentemot Uthyraren.",
    ],
  },
  {
    id: "6",
    title: "§6 Användning",
    text: [
      "Hyrestagaren ansvarar för att Hyresmaterielen endast brukas av därtill behörig och utbildad personal.",
      "Hyrestagaren får inte utan Uthyrarens skriftliga godkännande låta annan än Hyrestagaren använda Hyresmaterielen, hyra ut Hyresmaterielen i andra hand eller överlåta detta hyresavtal på annan. Hyrestagare som i strid med detta avtal låter annan bruka eller förfoga över Hyresmaterielen är ovillkorligen ansvarig för allt sådant brukande eller förfogande, i förhållande till såväl Uthyrarens som den andra brukaren och tredje man. Hyrestagaren är dessutom skyldig att ersätta Uthyrarens för den ekonomiska eller ideella skada som uppstår för Uthyraren genom att Hyresmaterielen brukas av annan än hyrestagaren",
      "För platsmonterad byggnadsställning och fallskydd gäller att hyrestagaren, på eget ansvar, får låta annan bruka Hyresmaterielen på det sätt den är konstruerad för och avsedd att brukas på.",
      "Hyrestagaren är skyldig att hålla uthyraren underrättad om var Hyresmaterielen förvaras eller användes. Hyresmaterielen får ej utan uthyrarens skriftliga medgivande föras utanför Sveriges gränser.",
    ],
  },
  {
    id: "7",
    title: "§7 Tillsyn och vård",
    text: [
      "Det åligger hyrestagaren att omgående vid mottagandet av Hyresmaterielen, och innan denna tas i bruk, utföra erforderlig kontroll och att framföra eventuella anmärkningar om dess skick till Uthyraren.",
      "Hyrestagaren får inte ändra produktmärkning, identifikation eller skriftliga användarinstruktioner avseende Hyresmaterielen.",
      "Hyrestagaren är skyldig att vårda Hyresmaterielen, att iakttaga utfärdade föreskrifter om Hyresmaterielens skötsel och vård samt att använda föreskrivna driv- och smörjmedel. Sådana driv- och smörjmedel skall bekostas av hyrestagaren.",
      "För tillsyn och vård av Hyresmaterielen skall hyrestagaren använda personal med för uppgiften erforderlig kompetens.",
      "Hyresmaterielen ska vid återlämning vara väl rengjord och i samma skick som vid leveranstillfället. Har Hyresmaterielen inte rengjorts eller dess skick på annat sätt försämrats under hyrestiden, debiteras hyrestagaren särskilt härför. För Hyresmateriel som kan ha utsatts för skadliga ämnen, t.ex. asbestdamm, debiteras en särskild saneringskostnad.",
    ],
  },
  {
    id: "8",
    title: "§8 Reparationer",
    text: [
      "Hyrestagaren får inte, med undantag för tillsyn och vård enligt § 7 ovan, utan Uthyrarens skriftliga godkännande låta reparera eller förändra Hyresmaterielen. Utbyte av slit- och förbrukningsdelar vid nyttjandet av Hyresmaterielen, efter godkännande från Uthyraren, utförs och bekostas av hyrestagaren.",
      "Skador och defekter i Hyresmaterielen som under hyrestiden uppstår på grund av hyrestagarens brukande av Hyresmaterielen eller annars på grund av hyrestagaren eller tredje man, ska beskostas av hyrestagaren. Reparationer av fel i Hyresmaterielen som förelegat vid uthyrningstillfället bekostas av Uthyraren. Uthyraren är inte i något fall skyldig att ställa ersättningsmateriel till hyrestagarens förfogande under reparationstiden.",
      "Vid skador på Hyresmaterielen som uppkommit på grund av hyrestagarens oaktsamhet, bristande tillsyn eller vård, debiteras stilleståndsersättning enligt Uthyrarens prislista under reparationstiden.",
    ],
  },
  {
    id: "9",
    title: "§9 Uthyrarens ansvar vid fel och skada",
    text: [
      "Uthyraren, som inte kan övervaka hyrestagarens användning av Hyresmaterielen och de omständigheter varunder Hyresmaterielen nyttjas, ansvarar inte för personskada, sakskada eller förmögenhetsskada som kan uppkomma vid hyrestagarens användning av Hyresmaterielen. Uthyraren ansvarar inte heller för direkta eller indirekta skador (t.ex. driftstopp, utebliven vinst) som åsamkas hyrestagaren till följd av fel på Hyresmaterielen. Konstateras fel i Hyresmaterielen, som inte beror på Hyrestagaren eller tredje mans brukande av Hyresmaterielen, vilket gör den obrukbar för hyrestagaren, ska Uthyraren avhjälpa felet inom skälig tid (genom reparation eller med skälig ersättningsmateriel). Bedömer Uthyraren att felet inte kan avhjälpas inom skälig tid eller till en skälig kostnad för Uthyraren, har Uthyraren rätt att avbryta avtalet. Hyresmaterielen ska då återlämnas till Uthyraren och hyrestagaren befrias från skyldighet att betala hyra från och med dagen för sådant återlämnande.",
    ],
  },
  {
    id: "10",
    title: "§10 Hyrestagarens ansvar vid fel, skada och stöld",
    text: [
      "Hyrestagaren skall utan dröjsmål underrätta Uthyraren om fel eller skada på, samt om stöld av, Hyresmaterielen under hyrestiden. Uthyraren skall efter sådan underrättelse meddela hyrestagaren vilka åtgärder hyrestagaren skall vidta. Vid försäkringsfall skall hyrestagaren så snart som möjligt göra skadeanmälan till sitt försäkringsbolag (se § 12 nedan). Det åligger hyrestagaren att vid stöld av Hyresmaterielen samt vid skadegörelse, göra polisanmälan på den ort där stölden/skadan skett och sända kopia av anmälan till Uthyraren och försäkringsbolaget.",
      "Vid längre hyrestider skall Hyresmaterielen besiktas gemensamt av Uthyraren och hyrestagaren, enligt schema som överenskommes, för att säkerställa Hyresmaterielens goda skick.",
      "Samtliga skador på Hyresmaterielen som inte förelåg vid leveranstillfället enligt § 2 ovan skall ersättas av hyrestagaren. Skadad eller förlorad Hyresmateriel som inte ersätts genom hyrestagarens försäkring debiteras hyrestagaren till nyanskaffningsvärde.",
    ],
  },
  {
    id: "11",
    title: "§11 Särskilda bestämmelser om bodar, vagnar och containers",
    text: [
      "Uthyrarens bodar, vagnar och containers (”BVC”, inkluderas i övrigt i ”Hyresmateriel”) enligt prislista är avsedda för bruk under arbetstid och får inte, utan särskilt avtal därom, användas för boende eller övernattning av något slag. Bodar för boende/övernattning offereras separat med specifikation som anger ändamålet.",
      "BVC utgör lös egendom och får inte nyttjas på sätt som innebär att Uthyrarens äganderätt till dessa riskerar att upphöra eller på annat sätt belastas. BVC får t ex inte inskrivas, pantsättas, sammanfogas med fast egendom eller överlåtas av hyrestagaren. Transporter samt etablering av BVC sker enligt hyresavtal.",
      "BVC får inte hyras ut i andra hand eller nyttjas av annan än hyrestagaren och hyrestagarens anställda. Hyrestagaren har dock rätt att, under eget ansvar, under arbetstid och på arbetsplatsen låta annan nyttja på plats etablerade arbetsbodar på det sätt de är avsedda att nyttjas, t ex för omklädning eller rast.",
      "Föremål som hyrestagaren tillför BVC är hyrestagarens lösa egendom som skall avlägsnas vid hyrestidens slut och återställande skall ske.",
      "Hyrestagaren ansvarar för alla nödvändiga tillstånd för placering, användning och borttagande/återställande av BVC, samt svarar för samtliga tillkommande kostnader och avgifter för uppfyllande av myndighetskrav med anledning av sådana tillstånd.",
      "Hyrestagaren svarar för samtliga kostnader för den tomt/område där BVC skall placeras samt att ytan och tillgänglighet är lämplig och förberedd för nyttjande av BVC.",
      "Uthyraren äger rätt att vid hyrestidens utgång och på hyrestagarens bekostnad utföra underhålls- och återställningsarbete för återställande av BVC till aktuell BVC:s skick vid leveranstillfället enligt § 2 ovan.",
    ],
  },
  {
    id: "12",
    title: "§12 Försenad eller felaktig leverans",
    text: [
      "Om Uthyraren inte tillhandahåller Hyresmaterielen i funktionsdugligt skick vid avtalad tidpunkt har hyrestagaren rätt att häva hyresavtalet eller begära sådan nedsättning av hyran som svarar mot felet eller dröjsmålet. Uthyraren är dock inte skyldig att ersätta hyrestagaren för annan direkt eller indirekt skada som hyrestagaren eller tredje man åsamkats till följd av felet eller dröjsmålet.",
      "Hävning, skadestånd eller nedsättning av hyran får inte göras gällande, när Uthyraren erbjuder sig att avhjälpa fel på Hyresmaterielen eller ersätta Hyresmaterielen med annan godtagbar Hyresmateriel, och rättelse sker inom skälig tid och utan kostnad för hyrestagaren. Sådan påföljd får inte heller göras gällande om inte hyrestagaren genast efter det han märkt, eller bort märka, felet meddelar Uthyraren härom (reklamation).",
    ],
  },
  {
    id: "13",
    title: "§13 Försäkring",
    text: [
      "Det åligger hyrestagaren att försäkra Hyresmaterielen till sitt fulla återanskaffningsvärde under hela hyrestiden. Uthyraren svarar för att trafikförsäkring finns på Hyresmateriel och fordon som enligt lag är försäkringspliktiga.",
    ],
  },
  {
    id: "14",
    title: "§14 Uthyrarens rätt att avbryta avtalsförhållandet m.m.",
    text: [
      "Uthyraren har rätt att omedelbart avbryta hyresavtalet och begära återlämnande av den uthyrda Hyresmaterielen om",
      "A) Hyrestagaren inte fullgör sina betalningsåtaganden gentemot Uthyraren då de förfaller till betalning, såvida inte dröjsmålet är av ringa betydelse för Uthyraren;",
      "B) Hyrestagaren efter begäran från Uthyraren (A) ej tillhandahåller en (i) moderbolagsgaranti i enlighet med bilaga 1 härtill, enligt vilken hyrestagarens moderbolag, som säkerhet för hyrestagarens rätta fullgörande av hyresavtalet, går i borgen (”on demand”) såsom för egen skuld och därigenom ansvarar för hyrestagarens samtliga skyldigheter och förpliktelser gentemot Uthyraren, eller tillhandahåller (ii) annan likvärdig och tillfredställande säkerhet som accepteras av Uthyraren, (B) om Uthyraren har skäl att befara bristande betalningsförmåga av moderbolaget under ställd moderbolagsgaranti eller om värdet på ställd säkerhet minskar efter ställandet av sådan säkerhet (och hyrestagaren inte efter begäran från Uthyraren kan erbjuda en kompletterande säkerhet som accepteras av Uthyraren), eller (C) om det sker en ägarförändring varigenom borgensman som tidigare varit moderbolag till hyrestagaren upphör att kontrollera minst 50 procent av aktierna i hyrestagaren;",
      "C) Uthyraren har skäl att befara en bristande betalningsförmåga hos hyrestagaren för träffat avtal och tillfredställande och begärd säkerhet inte ställs;",
      "D) Uthyraren kan visa på en väsentlig risk för förlust eller betydande skador på Hyresmaterielen, såvida tillfredställande säkerhet inte ställs eller hyrestagaren visar att risken för sådan förlust eller skada inte längre föreligger;",
      "E) Hyresmaterielen utsätts för onormal användning, brukas på ett oaktsamt sätt eller i övrigt används av personal utan erforderlig behörighet eller utbildning;",
      "F) Hyrestagaren utan Uthyrarens skriftliga godkännande låter annan än Hyrestagarens personal använda Hyresmaterielen;",
      "G) Hyrestagaren i övrigt åsidosätter bestämmelse i detta avtal, vars iakttagande inte är av endast ringa intresse för Uthyraren.",
      "Avbryter Uthyraren hyresavtalet till omedelbart upphörande enligt ovan upphör hyrestiden och hyrestagarens rätt att nyttja Hyresmaterielen. Hyrestagaren ska då bekosta omedelbar nedmontering, rengöring och återlämning av Hyresmaterielen till Uthyraren. Hyrestagarens skyldighet att betala hyra för Hyresmaterielen upphör genom sådan återlämning av Hyresmaterielen.",
      "Sker inte återlämning genom hyrestagarens försorg, ska hyrestagaren ersätta Uthyraren för kostnaden för att nedmontera, rengöra och hämta Hyresmaterielen. Hyrestagaren står risken för Hyresmaterielen till dess den är åter vid uthyrarens uthyrningsdepå.",
    ],
  },
  {
    id: "15",
    title: "§15 Force Majeure",
    text: [
      "Uthyraren är aldrig ansvarig för skada som uppstår för hyrestagaren på grund av felaktig eller försenad leverans av Hyresmateriel, om detta beror på lagbud, myndighetsåtgärd, krigshändelser, strejk, blockad, bojkott, lockout eller annan liknande omständighet.",
      "Uthyraren och hyrestagaren har rätt att avbryta hyresavtalet med omedelbar verkan om det kan visas att inträffade omständigheter enligt ovan medfört väsentliga olägenheter för Uthyraren respektive hyrestagaren, och inte vållats av Uthyraren eller hyrestagaren själv.",
    ],
  },
  {
    id: "16",
    title: "§16 Allmänna betalningsvillkor",
    text: [
      "Samtliga priser gäller exklusive mervärdeskatt. Hyrestagare som ej har överenskommen kredit betalar hyran i förskott. Om inte annat överenskommits är betalningstiden 10 dagar netto. Dröjsmålsränta debiteras med riksbankens fastställda vid varje tid gällande referensränta med tillägg av åtta procentenheter, vartill – i förekommande fall – kommer lagstadgad påminnelse- och inkassoavgift. Löpande fakturering sker två gånger per månad, avslutade hyresavtal faktureras snarast. Uthyraren förbehåller sig rätten att debitera faktureringsavgift samt uppläggningsavgift för nya konton. Om Uthyraren så påfordrar skall hyrestagaren deponera av Uthyraren begärt förskottsbelopp på hyra",
      "Uthyraren reserverar sig för ändringar i priser och försäkringsavgifter utan föregående avisering",
    ],
  },
];
