import React from "react";
import { Routes, Route } from "react-router";
import Start from "./Start";
import OurTools from "./OurTools";
import HowItWorks from "./HowItWorks";
import About from "./About";
import Contact from "./Contact";
import Conditions from "./Conditions";
import MailConfirmation from "./MailConfirmation";

export default function Pages() {
  return (
    <Routes>
      <Route path="/" element={<Start />}></Route>
      <Route path="/vara-verktyg" element={<OurTools />}></Route>
      <Route path="/hur-det-funkar" element={<HowItWorks />}></Route>
      <Route path="/hyresvillkor" element={<Conditions />}></Route>
      <Route path="/om-oss" element={<About />}></Route>
      <Route path="/kontakt" element={<Contact />}></Route>
      <Route path="/mail-bekraftelse" element={<MailConfirmation />}></Route>
    </Routes>
  );
}
